import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_ctx.props.radioButton)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_a_radio_group, {
          value: _ctx.computedValor,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValor) = $event)),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.defineValorMarcado($event))),
          "button-style": "solid"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_radio_button, {
              value: 1,
              title: "Dinheiro"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Dinheiro")
              ]),
              _: 1
            }),
            _createVNode(_component_a_radio_button, {
              value: 2,
              title: "Cheque"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Cheque")
              ]),
              _: 1
            }),
            _createVNode(_component_a_radio_button, {
              value: 3,
              title: "Cartão de Crédito"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Cartão de Crédito")
              ]),
              _: 1
            }),
            _createVNode(_component_a_radio_button, {
              value: 4,
              title: "Cartão de Débito"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Cartão de Débito")
              ]),
              _: 1
            }),
            _createVNode(_component_a_radio_button, {
              value: 5,
              title: "Crédito da Loja"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Crédito da Loja")
              ]),
              _: 1
            }),
            _createVNode(_component_a_radio_button, {
              value: 10,
              title: "Vale Alimentação"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Vale Alimentação")
              ]),
              _: 1
            }),
            _createVNode(_component_a_radio_button, {
              value: 11,
              title: "Vale Refeição"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Vale Refeição")
              ]),
              _: 1
            }),
            _createVNode(_component_a_radio_button, {
              value: 12,
              title: "Vale Presente"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Vale Presente")
              ]),
              _: 1
            }),
            _createVNode(_component_a_radio_button, {
              value: 13,
              title: "Vale Combustível"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Vale Combustível")
              ]),
              _: 1
            }),
            _createVNode(_component_a_radio_button, {
              value: 15,
              title: "Boleto Bancário"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Boleto Bancário")
              ]),
              _: 1
            }),
            _createVNode(_component_a_radio_button, {
              value: 16,
              title: "Depósito Bancário"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Depósito Bancário")
              ]),
              _: 1
            }),
            _createVNode(_component_a_radio_button, {
              value: 17,
              title: "Pix"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Pix")
              ]),
              _: 1
            }),
            _createVNode(_component_a_radio_button, {
              value: 18,
              title: "Transferência Bancária"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Transferência Bancária")
              ]),
              _: 1
            }),
            _createVNode(_component_a_radio_button, {
              value: 19,
              title: "Programa de Fidelidade de Crédito Virtual"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Programa de Fidelidade de Crédito Virtual")
              ]),
              _: 1
            }),
            _createVNode(_component_a_radio_button, {
              value: 90,
              title: "Sem Pagamento"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Sem Pagamento")
              ]),
              _: 1
            }),
            _createVNode(_component_a_radio_button, {
              value: 99,
              title: "Outros"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Outros")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_a_select, {
          value: _ctx.computedValor,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedValor) = $event)),
          onChange: _ctx.defineValorSelecionado,
          class: "ss-tamanho-completo"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select_option, {
              value: 1,
              title: "Dinheiro"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Dinheiro")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, {
              value: 2,
              title: "Cheque"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Cheque")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, {
              value: 3,
              title: "Cartão de Crédito"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Cartão de Crédito")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, {
              value: 4,
              title: "Cartão de Débito"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Cartão de Débito")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, {
              value: 5,
              title: "Crédito da Loja"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Crédito da Loja")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, {
              value: 10,
              title: "Vale Alimentação"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Vale Alimentação")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, {
              value: 11,
              title: "Vale Refeição"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Vale Refeição")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, {
              value: 12,
              title: "Vale Presente"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Vale Presente")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, {
              value: 13,
              title: "Vale Combustível"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Vale Combustível")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, {
              value: 15,
              title: "Boleto Bancário"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Boleto Bancário")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, {
              value: 16,
              title: "Depósito Bancário"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Depósito Bancário")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, {
              value: 17,
              title: "Pix"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Pix")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, {
              value: 18,
              title: "Transferência Bancária"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Transferência Bancária")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, {
              value: 19,
              title: "Programa de Fidelidade de Crédito Virtual"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Programa de Fidelidade de Crédito Virtual")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, {
              value: 90,
              title: "Sem Pagamento"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Sem Pagamento")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, {
              value: 99,
              title: "Outros"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Outros")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "onChange"])
      ]))
}